.faq_screen[data-v-151973ad] {
	padding-top: 150px;
    margin-left: 30px;
    margin-right: 30px;
}
.faq_top_block[data-v-151973ad] {
	display: flex;
	align-items: center;
}
.faq_title[data-v-151973ad] {
	font-size: 24px;
    color: #fff;
    font-weight: 700;
    margin-left: 20px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.tab[data-v-151973ad] {
  margin-top: 50px;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  width: 100%;
}

/* Style the buttons inside the tab */
.tab button[data-v-151973ad] {
  width: 100%;
  height: 100px;
  background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.2) 0%, rgba(24, 41, 101, 0.2) 100%);
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 21px;
  color: #fff;
  font-weight: 700;
  border-radius: 17px;
  transition-duration: .1s;
}

/* Change background color of buttons on hover */
.tab button[data-v-151973ad]:hover {
    background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.9) 0%, rgba(24, 41, 101, 0.9) 100%);
}
  
  /* Create an active/current tablink class */
.tab button.active[data-v-151973ad] {
    background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.9) 0%, rgba(24, 41, 101, 0.9) 100%);
}
.tab button[data-v-151973ad]:nth-child(2) {
	margin-left: 30px;
	margin-right: 30px;
}
.tablinks[data-v-151973ad] {
	display: flex;
	align-items: center;
	justify-content: center;
}
.tablinks p[data-v-151973ad] {
	margin-left: 10px;
    margin-bottom: 0;
}
.tabcontent[data-v-151973ad] {
	margin-top: 50px;
	padding: 50px;
	width: 100%;
	height: auto;
	border-radius: 10px;
	background-color: rgba(57, 64, 74,0.5);
	-webkit-backdrop-filter: blur(20px);
	        backdrop-filter: blur(20px);
}
.accordion-button[data-v-151973ad] {
	height: 60px;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
    font-family: "Roboto";
}
.accordion-button[data-v-151973ad]::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(../images/arrow-down.svg);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}
.accordion-button[data-v-151973ad]:not(.collapsed)::after {
    background-image: url(../images/arrow-top.svg);
    -webkit-transform: rotate(
180deg
);
            transform: rotate(
180deg
);
}
.accordion-body[data-v-151973ad] {
	font-size: 16px;
	line-height: 26px;
	color: #fff;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
}
.accordion-item .accordion-button[data-v-151973ad] {
	background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
	border: 0!important;
    border-radius: 13px;
}
.accordion-button[data-v-d74ccae2][data-v-151973ad]:not(.collapsed){
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.accordion-item .accordion-button[data-v-151973ad]:hover {
    background-color: rgba(57, 64, 74,0.8)!important;
}
.accordion-item[data-v-151973ad] {
	margin-bottom: 20px;
	border: 0!important;
    border-radius: 16px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
}
.accordion-item:last-of-type .accordion-button.collapsed[data-v-151973ad]{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.accordion-item:last-of-type .accordion-collapse[data-v-151973ad] {
    border-bottom-right-radius: 19px;
    border-bottom-left-radius:19px;
}
.accordion-button[data-v-151973ad]:focus {
    z-index: 3;
    border-color: transparent!important;
    border: 0!important;
    outline: 0;
    box-shadow: none!important;
}
.accordion-collapse[data-v-151973ad] {
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
}
.accordion-button[data-v-151973ad]:not(.collapsed) {
    color: #fff;
    font-size: 16px;
	font-weight: 700;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-body a[data-v-151973ad] {
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
    transition-duration: .1s;
}
.accordion-body a[data-v-151973ad]:hover {
    opacity: .5;
}


/* media adaptive */
@media (max-width: 1601px) {
.tablinks[data-v-151973ad] {
        height: 80px!important;
        font-size: 17px!important;
}
.tablinks img[data-v-151973ad] {
        width: 40px;
        height: 40px;
}
}
@media (max-width: 992px) {
.tab button[data-v-151973ad] {
    font-size: 15px;
}
.faq_screen[data-v-151973ad] {
        padding-top: 100px;
        margin-left: 10px;
        margin-right: 10px;
}
.tabcontent[data-v-151973ad] {
    padding: 30px 30px 10px 30px;
}
.accordion-body[data-v-151973ad] {
    font-size: 15px;
}
}
@media (max-width: 800px) {
.tab[data-v-151973ad] {
        display: block;
}
.tab button[data-v-151973ad]:nth-child(2) {
        margin-left: 0px;
        margin-right: 0px;
}
.tablinks[data-v-151973ad] {
        margin-bottom: 20px;
}
}
@media (max-width: 600px) {
.accordion-button[data-v-151973ad] {
        font-size: 16px!important;
}
.accordion-item .accordion-button[data-v-151973ad] {
    font-size: 14px!important;
    line-height: 20px;
}
.accordion-body[data-v-151973ad] {
        font-size: 13px!important;
        line-height: 22px!important;
}
.tabcontent[data-v-151973ad] {
    padding: 20px 20px 10px 20px;
}
}
@media (max-width: 380px) {
.accordion-button[data-v-151973ad] {
        font-size: 15px!important;
}
.accordion-item .accordion-button[data-v-151973ad] {
    font-size: 12px!important;
    line-height: 20px;
}
.accordion-body[data-v-151973ad] {
        font-size: 12px!important;
        line-height: 20px!important;
}
.tab button[data-v-151973ad] {
        font-size: 14px!important;
}
}