*[data-v-7d33884c],
p[data-v-7d33884c],
a[data-v-7d33884c],
li[data-v-7d33884c],
ul[data-v-7d33884c] {
  margin: 0;
  padding: 0;
}
div > .background[data-v-7d33884c] {
  font-size: 17px;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}
a[data-v-7d33884c],
a[data-v-7d33884c]:hover {
  text-decoration: none;
}
header[data-v-7d33884c] {
  z-index: 2050;
  position: fixed;
  width: 100%;
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
/* блюр */
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.nav-item-two[data-v-7d33884c] {
  display: none;
}
.navbar[data-v-7d33884c] {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.menu_list li[data-v-7d33884c] {
  list-style-type: none;
}
.menu_list[data-v-7d33884c] {
  display: flex;
  align-items: center;
}
.navbar-expand-lg[data-v-7d33884c] {
  padding: 15px 190px;
}
.navbar-toggler-icon[data-v-7d33884c] {
  background-image: url(../../assets/images/toggle-white.svg);
}
.navbar[data-v-7d33884c] {
  background: rgba(255, 0, 255, 0);
}
.navbar-brand[data-v-7d33884c] {
  width: 100%;
}
.nav-link[data-v-7d33884c] {
  margin: 0px 15px;
  padding: 0;
  color: #fff;
  transition-duration: 0.2s;
}
.nav-link[data-v-7d33884c]:hover,
.lang_button[data-v-7d33884c]:hover,
.navbar-light .nav-link[data-v-7d33884c]:focus {
  color: #f2ca6b;
}
.btn-group[data-v-7d33884c] {
  margin-left: 20px;
}
@media (min-width: 992px) {
.navbar-expand-lg[data-v-7d33884c] {
    flex-direction: row;
    align-items: center;
}
}
.lang_button[data-v-7d33884c] {
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  color: #fff;
  transition-duration: 0.2s;
}
.lang_text[data-v-7d33884c] {
  margin-left: 5px;
}
.dropdown-toggle[data-v-7d33884c] {
  color: #fff;
}
.login_button[data-v-7d33884c] {
  margin-left: 30px;
  width: 166px;
  height: 52px;
  color: #fff;
  border: 1px #fff solid;
  border-radius: 5px;
  padding: 14px 0px 14px 0px;
  display: flex;
  transition-duration: 0.1s;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.login_button[data-v-7d33884c]:hover {
  background: #fff;
  color: #f2ca6b;
  font-weight: 500;
}
.login_button:hover .login_icon[data-v-7d33884c] {
  fill: #f2ca6b;
}
.login_text[data-v-7d33884c] {
  padding: 0px 10px;
  text-decoration: none;
  font-size: 18px;
}
.register_button[data-v-7d33884c] {
  margin-left: 30px;
  width: 244px;
  height: 52px;
  color: #f2ca6b;
  border: 1px #f2ca6b solid;
  border-radius: 5px;
  padding: 14px 0px 14px 0px;
  display: flex;
  transition-duration: 0.1s;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.register_button[data-v-7d33884c]:hover {
  background: #f2ca6b;
  color: #fff;
  font-weight: 500;
}
.register_button:hover .register_icon[data-v-7d33884c] {
  fill: #fff;
}
.register_text[data-v-7d33884c] {
  padding: 0px 10px;
  text-decoration: none;
  font-size: 18px;
}
.menu_block[data-v-7d33884c] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* main page */
.main_page[data-v-7d33884c] {
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/main.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 100px;
}
.main_content[data-v-7d33884c] {
  color: #fff;
  padding-top: 150px;
}
.top_text[data-v-7d33884c] {
  margin-bottom: 45px;
  text-align: center;
}
.top_title[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 45px;
  font-weight: bold;
}
.top_description[data-v-7d33884c] {
  margin-top: 20px;
  text-transform: uppercase;
  font-size:27px;
  font-weight: 600;
}
.main_center[data-v-7d33884c] {
  margin-bottom: 35px;
  margin-top: 20px;
  text-align: center;
  background-image: url("../images/land-top-back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
  max-width: 1040px;
  background-position: center;
  padding: 24px;
  display: flex;
  justify-content: center;
}
.bottom_text[data-v-7d33884c] {
  margin-bottom: 45px;
  margin-top: 45px;
  text-align: center;
  position: relative;
}
.bottom_text .connection_is_good[data-v-7d33884c]{
  position: absolute;
  bottom: -38px;
  text-transform: uppercase;
  color: rgba(255, 0, 0, 0.75);
  border: 1px solid transparent;
  right:27%;
  font-size: 19px;
  padding: 5px;
  font-weight: 500;
  border-image: linear-gradient(to left top,#ff0e0e 10%,  transparent 30%, transparent 70%, #ff0e0e 90%);
  border-image-slice: 1;
}
.bottom_text img[data-v-7d33884c]{
  display: none;
}
.bottom_description[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 27px;
  font-weight: bold;
}
.bottom_title[data-v-7d33884c] {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 42px;
  font-weight: bold;
}
.main_banner[data-v-7d33884c] {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  height: auto;
  padding: 24px 60px;
  color: #f2ca6b;
  border-radius: 9px;
  border: 2px #f2ca6b solid;
}
.main_banner_title[data-v-7d33884c] {
  font-size: 24px;
  font-weight: 700;
}
.main_banner_description[data-v-7d33884c] {
  margin-top: 7px;
  font-size: 32px;
  font-weight: 700;
}

/* work page */
.work_page[data-v-7d33884c] {
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/work_back_first.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.work_text span[data-v-7d33884c] {
  font-size: 24px;
}
.work_content[data-v-7d33884c], .table_content[data-v-7d33884c] {
  text-align: center;
  padding: 100px 190px;
  color: #Fff;
}
.work_title[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 42px;
  font-weight: 600;
  margin-bottom: 20px;
}
.work_title_two[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
}
.work_text[data-v-7d33884c],.table_text[data-v-7d33884c] {
  font-size: 22px;
  font-weight: 400;
  line-height: 35px;
  color: #fff;
}
.work_video_block[data-v-7d33884c] {
  margin-top: 50px;
  position: relative;
  padding-bottom: 56.25%;
  border: 1px solid #f2ca6b;
}
.work_video_block iframe[data-v-7d33884c] {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.score_block img[data-v-7d33884c] {
  width: 80%;
  height: auto;
}

/* values block */
.values_block[data-v-7d33884c] {
  width: 100%;
  height: auto;
  position: relative;
  background-image: url("../../assets/images/value_back.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.form_content[data-v-7d33884c] {
  margin-top: 45px;
}
.values_content[data-v-7d33884c] {
  text-align: center;
  padding: 100px 80px;
}
.values_title[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 50px;
  color: #fff;
}
.value_item[data-v-7d33884c] {
  margin: 0 auto;
  text-align: center;
  width: 510px;
  height: 510px;
  background-image: url(../../assets/images/decor1.png);
  background-repeat: no-repeat;
}
.help-icon[data-v-7d33884c] {
  padding-top: 65px;
  width: 130px;
  height: 130px;
}
.net-icon[data-v-7d33884c] {
  padding-top: 115px;
  width: 180px;
  height: 180px;
}
.value_title[data-v-7d33884c] {
  padding-top: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}
.value_description[data-v-7d33884c] {
  padding-top: 30px;
  margin: 0 auto;
  width: 330px;
  font-size: 18px;
  color: #fff;
  line-height: 26px;
}
.form_logo_block[data-v-7d33884c] {
  margin: 0 auto;
  width: 250px;
  height: 250px;
  background-size: 250px 250px;
  background-image: url("../../assets/images/logo-form.svg");
  background-repeat: no-repeat;
}
.two_row[data-v-7d33884c] {
  margin-top: -120px;
  display: flex;
  align-items: flex-end;
}
.three_row[data-v-7d33884c] {
  margin: 0 auto;
  width: 1150px;
  display: flex;
  margin-top: 50px;
}
.values_bottom_block[data-v-7d33884c] {
  margin-top: 100px;
  text-align: center;
}
.bottom_block_title[data-v-7d33884c] {
  line-height: 60px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 32px;
}

/* tired block */
.tired_block[data-v-7d33884c] {
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/tired_back.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.white_decor[data-v-7d33884c] {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
}
.tired_title[data-v-7d33884c] {
  text-transform: uppercase;
  color: #fff;
  font-size: 42px;
  font-weight: 600;
  text-align: center;
}
.tired_content[data-v-7d33884c] {
  padding: 100px 40px;
}
.item_img[data-v-7d33884c] {
  z-index: 1050;
}
.tired_item[data-v-7d33884c] {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
}
.item_content[data-v-7d33884c] {
  padding: 50px 120px;
  margin-left: -65px;
  width: 70%;
  height: 280px;
  border-radius: 0px 60px 0px 0px;
}
.white_item_decor[data-v-7d33884c] {
  width: 162px;
}
.item_title[data-v-7d33884c] {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}
.item_description[data-v-7d33884c] {
  width: auto;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}
.tired_item_two[data-v-7d33884c] {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
}
.item_content_two[data-v-7d33884c] {
  margin-right: -65px;
  padding: 50px 100px;
  width: 60%;
  height: 280px;
  border-radius: 0px 0px 0px 60px;
}
.bottom_tired_title[data-v-7d33884c] {
  text-align: center;
  margin: 0 auto;
  margin-top: 90px;
  width: 1350px;
  line-height: 60px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 46px;
}

/* status block */
.status_block[data-v-7d33884c] {
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/work_back.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.status_content[data-v-7d33884c] {
  text-align: center;
  margin: 0 auto;
  padding: 100px 190px;
}
.cards_group[data-v-7d33884c] {
  margin-bottom: 65px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
.status_title[data-v-7d33884c] {
  font-size: 42px;
  font-weight: 700;
  color: #fff;
}
.status_card[data-v-7d33884c] {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 70px 0px 110px;
  width: 480px;
  height: auto;
  color: #fff;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.status_card.sp[data-v-7d33884c]{
  background-image: url("../images/status_back_sp.png");
}
.status_card.fp[data-v-7d33884c]{
  background-image: url("../images/status_back_fp.png");
}
.card_title_blue[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 42px;
  font-weight: 700;
  color: #54cdef;
}
.card_title_blue span[data-v-7d33884c]{
  color: #fff;
}
.card_price_blue[data-v-7d33884c] {
  font-weight: 400;
  font-size: 32px;
  color: #54cdef;
  position: absolute;
}
.card_description[data-v-7d33884c] {
  font-size: 18px;
  line-height: 34px;
  margin-top: 40px;
  margin-bottom: 58px;
  padding: 0px 50px;
}
.card_description span[data-v-7d33884c]{
 color: #54cdef;
}
.status_yellow_card .card_description span[data-v-7d33884c]{
  color: #F2CA6B;
}
.status_card.fp .card_description span[data-v-7d33884c]{
  color: #5CD58E;
}
.card_btn_blue[data-v-7d33884c] {
  color: #54cdef;
  font-size: 18px;
  font-weight: 700;
  padding: 18px 60px;
  border: 1px #54cdef solid;
  border-radius: 5px;
  transition-duration: 0.1s;
}
.card_btn_blue[data-v-7d33884c]:hover {
  color: #fff;
  background: #54cdef;
}
.status_yellow_card[data-v-7d33884c] {
  position: relative;
  color: #fff;
  text-align: center;
  width: 550px;
  padding: 70px 0px 110px;
  height: auto;
  border-radius: 10px;
  z-index: 1051;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  background-image: url("../images/status_back_mp.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  justify-content: space-between;
}
.card_title_yellow[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 42px;
  font-weight: 700;
  color: #f2ca6b;
}
.card_title_yellow span[data-v-7d33884c]{
  color: #fff;
}
.card_price_yellow[data-v-7d33884c] {
  font-weight: 400;
  font-size: 38px;
  color: #f2ca6b;
  position: absolute;
}
.card_btn_yellow[data-v-7d33884c] {
  color: #f2ca6b;
  font-size: 18px;
  font-weight: 700;
  padding: 18px 60px;
  border: 1px #f2ca6b solid;
  border-radius: 5px;
  transition-duration: 0.1s;
}
.card_btn_yellow[data-v-7d33884c]:hover {
  color: #fff;
  background: #f2ca6b;
}
.card_title_green[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 42px;
  font-weight: 700;
  color: #5cd58e;
}
.card_title_green span[data-v-7d33884c]{
  color: #fff;
}
.card_price_green[data-v-7d33884c] {
  font-weight: 400;
  font-size: 32px;
  color: #5cd58e;
  position: absolute;
}
.card_btn_green[data-v-7d33884c] {
  color: #5cd58e;
  font-size: 18px;
  font-weight: 700;
  padding: 18px 60px;
  border: 1px #5cd58e solid;
  border-radius: 5px;
  transition-duration: 0.1s;
}
.card_btn_green[data-v-7d33884c]:hover {
  background: #5cd58e;
  color: #fff;
}
.table_block[data-v-7d33884c] {
  width: 100%;
  height: auto;
  background-image: url("../../assets/images/background-man.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.table_img_block[data-v-7d33884c] {
  position: absolute;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%, 65%);
          transform: translate(-50%, 65%);
  z-index: 2000;
  max-width: 754px;
  -webkit-animation-name: showtable-data-v-7d33884c;
          animation-name: showtable-data-v-7d33884c;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  transition: opacity 2s;
}
@-webkit-keyframes showtable-data-v-7d33884c {
from{
    opacity: 0;
}
to{
    opacity: 1;
}
}
@keyframes showtable-data-v-7d33884c {
from{
    opacity: 0;
}
to{
    opacity: 1;
}
}
.table_content[data-v-7d33884c] {
  padding-top: 100px;
  text-align: center;
  position: relative;
}
.score_block[data-v-7d33884c] {
  margin-top: 25px;
}
.table_title[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 42px;
  color: #fff;
  font-weight: 700;
}
.table_bottom[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 42px;
  color: #fff;
  margin-top: 200px;
  font-weight: 700;
}

/* start block */
.start_block[data-v-7d33884c] {
  width: 100%;
  height: auto;
  padding-bottom: 100px;
  background-image: url("../../assets/images/start_back.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.start_content[data-v-7d33884c] {
  padding-top: 100px;
  text-align: center;
  margin: 0 auto;
}
.start_title[data-v-7d33884c] {
  text-transform: uppercase;
  font-size: 42px;
  font-weight: 700;
  color: #fff;
}
.item_icon[data-v-7d33884c] {
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.start_one_item[data-v-7d33884c] {
  background-position: 15px -100px;
  background-repeat: no-repeat;
  text-align: center;
  width: 510px;
  height: 390px;
}
.start_two_item[data-v-7d33884c] {
  background-position: 15px -100px;
  background-repeat: no-repeat;
  text-align: center;
  width: 510px;
  height: 390px;
  margin: 0px 50px;
}
.start_three_item[data-v-7d33884c] {
  background-position: 15px -100px;
  background-repeat: no-repeat;
  text-align: center;
  width: 510px;
  height: 390px;
}
.start_four_item[data-v-7d33884c] {
  background-position: 15px -100px;
  background-repeat: no-repeat;
  text-align: center;
  width: 510px;
  height: 390px;
}
.start_five_item[data-v-7d33884c] {
  margin: 0 auto;
  background-position: 15px -100px;
  background-repeat: no-repeat;
  text-align: center;
  width: 510px;
  height: 390px;
  margin: 0px 50px;
}
.start_six_item[data-v-7d33884c] {
  background-position: 15px -100px;
  background-repeat: no-repeat;
  text-align: center;
  width: 510px;
  height: 390px;
}
.start_item_title[data-v-7d33884c] {
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 25px;
  color: #fff;
}
.start_item_description[data-v-7d33884c] {
  font-size: 20px;
  line-height: 34px;
  color: #fff;
}
.items_one_row[data-v-7d33884c] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}
.start_items_block[data-v-7d33884c] {
  margin-bottom: 15vh;
}
.over_block[data-v-7d33884c] {
  margin-top: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.over_button[data-v-7d33884c] {
  padding: 24px 60px;
  font-size: 24px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(17px);
          backdrop-filter: blur(17px);
  border-radius: 16px;
  border: 1px solid #ffc02b;
  color: #fff;
  transition-duration: 0.2s;
}
.over_button[data-v-7d33884c]:hover {
  background: #ffc02b;
  color: #fff;
}

/* footer */
footer[data-v-7d33884c] {
  width: 100%;
  height: auto;
  background: #000;
}
.social_media[data-v-7d33884c] {
  padding-top: 40px;
  position: relative;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contract_block[data-v-7d33884c] {
  margin-left: 60px;
}
.telegram_block[data-v-7d33884c],
.contract_block[data-v-7d33884c],
.pdf_block[data-v-7d33884c] {
  display: flex;
  align-items: center;
}
.pdf_block img[data-v-7d33884c]{
  margin-right: 15px;
}
.pdf_block[data-v-7d33884c]{
  margin-left: 60px;
  color: #fff;
  text-decoration: underline;
}
.telegram_block span[data-v-7d33884c],
.contract_block span[data-v-7d33884c] {
  font-size: 20px;
  color: #fff;
  margin-left: 15px;
}
.telegram_block span[data-v-7d33884c] {
  text-transform: uppercase;
}
hr[data-v-7d33884c] {
  height: 1px;
  border: 0;
  background-color: #fff;
}
.copyright_block[data-v-7d33884c] {
  padding-top: 30px;
  position: relative;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.copyright_text[data-v-7d33884c] {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.copyright_link[data-v-7d33884c] {
  text-decoration: none;
  color: #f2ca6b;
  font-size: 20px;
  font-weight: 700;
  transition-duration: 0.1s;
}
.copyright_link[data-v-7d33884c]:hover {
  color: #ffc02b;
}
[data-v-7d33884c]::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
[data-v-7d33884c]::-webkit-scrollbar-button {
  background-color: #666;
}
[data-v-7d33884c]::-webkit-scrollbar-track {
  background-color: #999;
}
[data-v-7d33884c]::-webkit-scrollbar-track-piece {
  width: 50px;
  background-color: #ffffff;
}
[data-v-7d33884c]::-webkit-scrollbar-thumb {
  width: 50px;
  height: 50px;
  background-color: #f2ca6b;
  border-radius: 3px;
}
[data-v-7d33884c]::-webkit-scrollbar-corner {
  width: 50px;
  background-color: #999;
}
[data-v-7d33884c]::-webkit-resizer {
  width: 50px;
  background-color: #666;
}
.navbar-expand-lg .dropdown-menu[data-v-7d33884c] {
  position: absolute;
  background: transparent;
  border: 0;
}
.dropdown-item[data-v-7d33884c]:focus,
.dropdown-item[data-v-7d33884c]:hover {
  color: #f2ca6b;
  background-color: transparent;
}
.dropdown-item[data-v-7d33884c] {
  display: block;
  width: 100%;
  padding: 5px;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.table-responsive[data-v-7d33884c] {
  padding-left: 1;
  margin-bottom: 50px;
}

/* modal */
.modal[data-v-7d33884c] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
#copyright_modal[data-v-7d33884c] {
  padding: 0px !important;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.fade-item_image[data-v-7d33884c]{
  margin-bottom: 10px;
}
.modal-header[data-v-7d33884c] {
  background: linear-gradient( 0deg, rgb(109 109 109 / 26%), rgb(67 67 67 / 26%));
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  display: flex;
  height: 120px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
  border-bottom: 0 !important;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close[data-v-7d33884c]{
  background: url(../../assets/images/close.svg)  center/1em auto
    no-repeat !important;
}
.modal-title[data-v-7d33884c] {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin: 0 auto;
}
.card_price_info[data-v-7d33884c] {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 576px) {
.modal-dialog[data-v-7d33884c] {
    max-width: 1200px;
    margin: 1.75rem auto;
}
}
@media (max-width: 560px){
.main_content[data-v-7d33884c]{
    padding-top: 120px;
}
.main_page[data-v-7d33884c]{
    background-image: url("../images/main_mobile.jpg");
}
.table_content[data-v-7d33884c] {
    padding-top:50px;
    text-align: center;
}
.table_title[data-v-7d33884c], .table_bottom[data-v-7d33884c]{
    font-size: 30px;
}
.top_text[data-v-7d33884c]{
    margin-bottom: 45px;
}
.bottom_text[data-v-7d33884c]{
    margin-top: 45px;
    margin-bottom: 180px;
}
.modal-title[data-v-7d33884c] {
    font-size: 18px;
}
.bottom_text .connection_is_good[data-v-7d33884c]{
    bottom: -130px;
    color: rgba(255, 0, 0, 0.75);
    right: 0;
    font-size: 14px;
}
.bottom_text img[data-v-7d33884c]{
    position: absolute;
    display: block;
    right: 0;
    bottom: -100px;
}
}
.modal-content[data-v-7d33884c] {
  width: 1200px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.26));
}
.modal_text_title[data-v-7d33884c] {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
.modal_text[data-v-7d33884c] {
  margin-top: 20px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}
.modal-body[data-v-7d33884c] {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem 60px;
}
.modal_item[data-v-7d33884c] {
  margin-bottom: 25px;
}
.fade-item[data-v-7d33884c] {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table_diagramma[data-v-7d33884c]{
  margin-top: 100px;
  position: relative;
}
.table_diagramma-img[data-v-7d33884c]{
  position: absolute;
  top: 50%; 
  -webkit-transform: translate(50%, -50%); 
          transform: translate(50%, -50%);
  right: 50%;
}
.table_diagramma-button[data-v-7d33884c]{
  position: absolute;
  top: 50%; 
  -webkit-transform: translate(50%, -86%); 
          transform: translate(50%, -86%);
  right: 50%;
  background: rgba(53, 53, 53, 0.24);
  border-radius: 14.1272px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border: 1px solid #ffc02b;
  color: #fff;
  width: 210px;
  height: 50px;
  transition: .2s;
  z-index: 1000;
}
.table_diagramma-button[data-v-7d33884c]:hover{
  background: #ffc02b;
}
.table_diagramma-img img[data-v-7d33884c]{
  width: 400px;
}
.fade-item.diagramma[data-v-7d33884c]{
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 0;
  justify-content: center;
  margin-bottom: -135px;
}
.fade-item.diagramma.reverse[data-v-7d33884c] {
  align-items: flex-end;
  flex-direction: column;
}
.fade-item.reverse[data-v-7d33884c] {
  flex-direction: row-reverse;
}
.fade-item > img[data-v-7d33884c] {
  width: 30%;
}
.fade-item-text[data-v-7d33884c] {
  margin-left: 50px;
  margin-right: 50px;
  text-align: start;
  margin-top: 20px;
  width: 80%;
}
.fade-item-text.diagramma[data-v-7d33884c]{
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 0;
  max-width: 300px;
}
.fade-item-title[data-v-7d33884c] {
  font-size: 32px;
  color: white;
  font-weight: 700;
}
.fade-item-desc[data-v-7d33884c] {
  font-size: 30px;
  color: white;
  font-weight: 400;
}
.fade-item-desc.diagramma[data-v-7d33884c]{
  font-size: 18px;
}

/* media adaptive screen */
@media (max-width: 1920px) {
body[data-v-7d33884c],
  html[data-v-7d33884c] {
    width: 100%;
    overflow-x: hidden;
    height: auto;
}
}
@media (max-width: 1701px) {
.value_item[data-v-7d33884c] {
    padding-top: 100px;
    width: 400px;
    height: 400px;
    background-size: 400px 400px;
}
.help-icon[data-v-7d33884c],
  .net-icon[data-v-7d33884c] {
    display: none;
}
.value_description[data-v-7d33884c] {
    font-size: 16px;
}
.value_title[data-v-7d33884c] {
    font-size: 18px;
    padding-top: 0px;
}
.form_logo_block[data-v-7d33884c] {
    margin: 0 auto;
    width: 180px;
    height: 180px;
    background-size: 180px 180px;
    background-image: url(../../assets/images/logo-form.svg);
    background-repeat: no-repeat;
}
.three_row[data-v-7d33884c] {
    width: 70%;
}
}
.table_img_close[data-v-7d33884c]{
  position: absolute;
  top: 37%;
  z-index: 3000;
  right: 50%;
  left: 50%;
  -webkit-transform: translate(943%, 37%);
          transform: translate(943%, 37%);
  cursor: pointer;
  height: 35px;
}
@media (max-width: 1601px) {
.fade-item[data-v-7d33884c] {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 20px;
    flex-direction: row;
}
.table_img_close[data-v-7d33884c]{
    -webkit-transform: translate(943%, 30%);
            transform: translate(943%, 30%);
}
.fade-item > img[data-v-7d33884c] {
    width: 30%;
}
.fade-item-text[data-v-7d33884c] {
    margin-left: 50px;
    margin-right: 50px;
    text-align: start;
    margin-top: 20px;
    width: 80%;
}
.fade-item-title[data-v-7d33884c] {
    font-size: 22px;
}
.fade-item-desc[data-v-7d33884c] {
    font-size: 20px;
}
.login_text[data-v-7d33884c],
  .register_text[data-v-7d33884c] {
    font-size: 16px;
}
.main_center[data-v-7d33884c] {
    margin-bottom: 30px;
}
.main_center .main_logo[data-v-7d33884c] {
    width: 80%;
    height: auto;
}
.top_title[data-v-7d33884c],
  .bottom_title[data-v-7d33884c] {
    font-size: 30px;
}
.top_description[data-v-7d33884c],
  .bottom_description[data-v-7d33884c] {
    font-size: 23px;
}
.main_banner[data-v-7d33884c] {
    padding: 24px;
}
.work_title[data-v-7d33884c] {
    font-size: 30px;
}
.work_text[data-v-7d33884c],.table_text[data-v-7d33884c] {
    font-size: 17px;
    line-height: 30px;
}
.work_text span[data-v-7d33884c] {
    font-size: 18px;
    line-height: 30px;
}
.bottom_block_title[data-v-7d33884c],
  .values_title[data-v-7d33884c] {
    line-height: 50px;
    font-size: 30px;
}
.item_description[data-v-7d33884c] {
    font-size: 16px;
}
.item_title[data-v-7d33884c] {
    font-size: 18px;
}
.status_content[data-v-7d33884c] {
    padding: 100px 100px;
}
.card_price_info[data-v-7d33884c] {
    top: -20px;
}
.card_title_blue[data-v-7d33884c],
  .card_title_yellow[data-v-7d33884c],
  .card_title_green[data-v-7d33884c],
  .card_price_blue[data-v-7d33884c] {
    font-size: 30px;
}
.card_price_yellow[data-v-7d33884c] {
    font-size: 32px;
}
.over_button[data-v-7d33884c] {
    font-size: 20px;
}
.over_button.yellow[data-v-7d33884c]:hover {
    background-color: #f2ca6b;
}
.card_description[data-v-7d33884c] {
    font-size: 16px;
    line-height: 28px;
    margin-top: 80px;
    margin-bottom: 100px;
}
.status_title[data-v-7d33884c],
  .start_title[data-v-7d33884c],
  .table_title[data-v-7d33884c],
  .table_bottom[data-v-7d33884c] {
    font-size: 30px;
}
.start_item_title[data-v-7d33884c] {
    font-size: 20px;
}
.start_item_description[data-v-7d33884c] {
    font-size: 16px;
    line-height: 30px;
}
.start_content[data-v-7d33884c] {
    padding-left: 60px;
    padding-right: 60px;
}
.copyright_link[data-v-7d33884c],
  .copyright_text[data-v-7d33884c],
  .telegram_block span[data-v-7d33884c],
  .contract_block span[data-v-7d33884c] {
    font-size: 16px;
}
}
@media (max-width: 1439px) {
.fade-item[data-v-7d33884c] {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 20px;
    flex-direction: row;
}
.main_center[data-v-7d33884c]{
    max-width: 850px;
}
.fade-item > img[data-v-7d33884c] {
    width: 30%;
}
.fade-item-text[data-v-7d33884c] {
    margin-left: 50px;
    margin-right: 50px;
    text-align: start;
    margin-top: 20px;
    width: 80%;
}
.fade-item-title[data-v-7d33884c] {
    font-size: 22px;
}
.fade-item-desc[data-v-7d33884c] {
    font-size: 20px;
}
.value_description[data-v-7d33884c] {
    font-size: 14px;
    line-height: 23px;
}
.value_title[data-v-7d33884c] {
    font-size: 17px;
}
.value_description[data-v-7d33884c] {
    width: 280px;
    font-size: 13px;
    padding-top: 20px;
}
.value_item[data-v-7d33884c] {
    padding-top: 80px;
    width: 350px;
    height: 350px;
    background-size: 350px 350px;
}
.item_title[data-v-7d33884c] {
    font-size: 16px;
}
.item_description[data-v-7d33884c] {
    font-size: 14px;
}
.card_description[data-v-7d33884c] {
    font-size: 15px;
    line-height: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0px 50px;
}
.card_title_blue[data-v-7d33884c],
  .card_title_yellow[data-v-7d33884c],
  .card_title_green[data-v-7d33884c],
  .card_price_blue[data-v-7d33884c],
  .card_price_yellow[data-v-7d33884c],
  .card_price_green[data-v-7d33884c] {
    font-size: 20px;
}
.card_price_info > img[data-v-7d33884c] {
    height: 40px;
}
}
@media (max-width: 1390px) {
.items_one_row[data-v-7d33884c] {
    margin-top: 20px;
}
.bottom_tired_title[data-v-7d33884c]{
    font-size: 30px;
    line-height: 30px;
    margin-top: 45px;
    width: auto;
}
.form_logo_block[data-v-7d33884c] {
    width: 150px;
    height: 150px;
    background-size: 150px 150px;
}
.value_description[data-v-7d33884c] {
    font-size: 13px;
}
.value_description[data-v-7d33884c] {
    font-size: 11px;
    padding-top: 15px;
    line-height: 20px;
}
.value_title[data-v-7d33884c] {
    font-size: 15px;
}
.value_item[data-v-7d33884c] {
    padding-top: 75px;
    width: 300px;
    height: 300px;
    background-size: 300px 300px;
}
.three_row .value_item[data-v-7d33884c] {
    padding-top: 100px;
}
.three_row[data-v-7d33884c] {
    width: 75%;
}
}
@media (max-width: 1310px) {
.fade-item[data-v-7d33884c] {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 20px;
    flex-direction: row;
}
.table_img_close[data-v-7d33884c]{
    -webkit-transform: translate(943%, -17%);
            transform: translate(943%, -17%);
}
.fade-item.diagramma .fade-item_image[data-v-7d33884c]{
    width: 200px !important;
    margin-right: 25px !important;
}
.fade-item.diagramma.second .fade-item_image[data-v-7d33884c]{
    margin-left: 25px !important;
}
.fade-item-text.diagramma[data-v-7d33884c] {
    max-width: 250px;
}
.fade-item-desc.diagramma[data-v-7d33884c]{
    font-size: 18px;
}
.fade-item > img[data-v-7d33884c] {
    width: 25%;
}
.fade-item-text[data-v-7d33884c] {
    margin-left: 50px;
    margin-right: 50px;
    text-align: start;
    margin-top: 20px;
    width: 80%;
}
.fade-item-title[data-v-7d33884c] {
    font-size: 16px;
}
.fade-item-desc[data-v-7d33884c] {
    font-size: 14px;
}
.navbar-expand-lg[data-v-7d33884c] {
    padding: 15px 10px;
}
.card_price_info[data-v-7d33884c] {
    top: 10px;
}
.main_banner[data-v-7d33884c] {
    width: 90%;
}
.work_content[data-v-7d33884c],.table_content[data-v-7d33884c] {
    padding: 100px 80px;
}
.items_one_row[data-v-7d33884c] {
    margin-top: 70px;
}
}
@media (max-width: 1100px) {
.table_img_close[data-v-7d33884c]{
    -webkit-transform: translate(943%, -44%);
            transform: translate(943%, -44%);
}
}
@media (max-width: 966px){
.table_img_close[data-v-7d33884c]{
    -webkit-transform: translate(943%, -38%);
            transform: translate(943%, -38%);
}
}
@media (max-width: 992px) {
.items_one_row[data-v-7d33884c] {
    margin-top: 0;
}
.table_img_close[data-v-7d33884c]{
    top: 33% !important;
    -webkit-transform: translate(943%, -12%);
            transform: translate(943%, -12%);
}
.table_img_block[data-v-7d33884c]{
    -webkit-transform: translate(-50%, 95%);
            transform: translate(-50%, 95%);
}
.table_bottom[data-v-7d33884c]{
    margin-top: 65px;
}
.main_center[data-v-7d33884c]{
    max-width: 650px;
}
.fade-item[data-v-7d33884c] {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 40px;
    flex-direction: column;
}
.fade-item.diagramma.reverse .fade-item_icon[data-v-7d33884c]{
    right: 5px !important;
}
.fade-item.diagramma.second .fade-item_icon[data-v-7d33884c]{
    left: 5px !important;
}
.fade-item.diagramma[data-v-7d33884c]{
    align-items: center;
    margin-bottom: 0 !important;
}
.table_diagramma-img[data-v-7d33884c]{
    top: 37%;
}
.table_diagramma-button[data-v-7d33884c]{
    top: 37%;
}
.fade-item.diagramma.second[data-v-7d33884c]{
    margin-top: 370px;
}
.fade-item.diagramma .fade-item_image[data-v-7d33884c]{
    margin-left: 0 !important;
}
.fade-item.diagramma.reverse[data-v-7d33884c]{
    align-items: center;
}
.fade-item.diagramma.reverse img[data-v-7d33884c]{
    margin-right: 0 !important;
}
.fade-item.reverse[data-v-7d33884c] {
    flex-direction: column;
}
.fade-item > img[data-v-7d33884c] {
    width: 50%;
}
.fade-item-text[data-v-7d33884c] {
    margin-left: 50px;
    margin-right: 50px;
    text-align: center !important;
    margin-top: 20px;
    width: 60%;
}
.fade-item-title[data-v-7d33884c] {
    font-size: 16px;
}
.fade-item-desc[data-v-7d33884c] {
    font-size: 14px;
}
.navbar-brand[data-v-7d33884c] {
    width: auto;
}
.card_price_info[data-v-7d33884c] {
    top: 15px;
}
.menu_list[data-v-7d33884c] {
    display: flex;
    align-items: center;
}
svg[data-v-7d33884c] {
    display: none;
}
.register_button[data-v-7d33884c] {
    margin-left: 15px;
    width: 180px;
}
.login_button[data-v-7d33884c] {
    width: 130px;
}
.login_text[data-v-7d33884c],
  .register_text[data-v-7d33884c],
  .lang_text[data-v-7d33884c],
  .nav-link[data-v-7d33884c],
  .copyright_text[data-v-7d33884c],
  .copyright_link[data-v-7d33884c],
  .telegram_block span[data-v-7d33884c],
  .contract_block span[data-v-7d33884c] {
    font-size: 16px;
}
.main_banner_title[data-v-7d33884c] {
    font-size: 20px;
}
.main_banner_description[data-v-7d33884c] {
    font-size: 25px;
}
.work_title[data-v-7d33884c] {
    font-size: 30px;
}
.work_title_two[data-v-7d33884c] {
    font-size: 22px;
}
.form_logo_block[data-v-7d33884c] {
    display: none;
}
.two_row[data-v-7d33884c],
  .three_row[data-v-7d33884c] {
    display: block;
    margin-top: 0px;
}
.help-icon[data-v-7d33884c],
  .net-icon[data-v-7d33884c] {
    width: 15%;
    padding-top: 0px;
    display: block;
    margin: 0 auto;
}
.value_item[data-v-7d33884c] {
    padding-top: 75px;
    width: 530px;
    height: 530px;
    background-size: 530px 530px;
    margin-bottom: 65px;
}
.value_description[data-v-7d33884c] {
    width: 280px;
    font-size: 17px;
    padding-top: 15px;
    line-height: 25px;
}
.value_title[data-v-7d33884c] {
    margin-top: 20px;
    font-size: 22px;
}
.three_row[data-v-7d33884c] {
    width: 100%;
}
.values_content[data-v-7d33884c] {
    padding: 100px 30px;
}
.one_items_row[data-v-7d33884c] {
    margin-top: 200px;
    margin-bottom: 130px;
}
.tired_item[data-v-7d33884c],
  .tired_item_two[data-v-7d33884c] {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 330px;
    width: 400px;
    height: 550px;
}
.tired_item_two[data-v-7d33884c] {
    -webkit-transform: translate(0px, 30%);
            transform: translate(0px, 30%);
}
.item_img_two[data-v-7d33884c] {
    -webkit-transform: translate(0px, -335px);
            transform: translate(0px, -335px);
    width: 70%;
    position: relative;
}
.item_img_three[data-v-7d33884c] {
    -webkit-transform: translate(0px, -385px);
            transform: translate(0px, -385px);
    width: 70%;
    position: relative;
}
.item_img[data-v-7d33884c] {
    width: 70%;
    position: relative;
}
.item_content[data-v-7d33884c] {
    padding: 0px 15px;
}
.item_content[data-v-7d33884c],
  .item_content_two[data-v-7d33884c] {
    padding: 250px 20px 50px 20px;
    margin: 0 auto;
    border-radius: 0px;
    margin: -230px auto;
    height: auto;
}
.item_content_two[data-v-7d33884c] {
    width: 70%;
}
.item_img[data-v-7d33884c] {
    width: 70%;
    position: relative;
}
.tired_content[data-v-7d33884c] {
    padding: 100px 0px;
}
.cards_group[data-v-7d33884c] {
    display: block;
}
.status_card[data-v-7d33884c],
  .status_yellow_card[data-v-7d33884c] {
    width: 100%;
}
.status_yellow_card[data-v-7d33884c] {
    margin: 30px 0px;
}
.status_card[data-v-7d33884c] {
    box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
}
.card_description[data-v-7d33884c] {
    font-size: 17px;
    line-height: 30px;
}
.card_description[data-v-7d33884c] {
    margin-bottom: 20px;
}
.card_title_blue[data-v-7d33884c],
  .card_title_yellow[data-v-7d33884c],
  .card_title_green[data-v-7d33884c],
  .card_price_blue[data-v-7d33884c] {
    font-size: 24px;
}
.items_one_row[data-v-7d33884c] {
    flex-wrap: wrap;
}
.start_content[data-v-7d33884c] {
    padding-left: 0px;
    padding-right: 0px;
}
.modal-content[data-v-7d33884c] {
    width: 100%;
}
.values_content[data-v-7d33884c] {
    padding: 100px 30px;
}
.one_items_row[data-v-7d33884c] {
    margin-top: 200px;
}
.two_items_row[data-v-7d33884c] {
    margin-top: 320px;
}
.tired_item[data-v-7d33884c],
  .tired_item_two[data-v-7d33884c] {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 130px;
}
.item_img[data-v-7d33884c] {
    width: 400px;
    margin-top: -145px;
    margin-bottom: 10vh;
}
.item_content[data-v-7d33884c] {
    padding: 0px 15px;
}
.item_content[data-v-7d33884c],
  .item_content_two[data-v-7d33884c] {
    padding: 200px 20px 50px 20px;
    margin: 0 auto;
    border-radius: 0px;
    margin: -250px auto;
    height: auto;
}
.item_img[data-v-7d33884c] {
    width: 70%;
    position: relative;
}
.tired_content[data-v-7d33884c] {
    padding: 100px 0px;
}
.cards_group[data-v-7d33884c] {
    display: block;
}
.status_card[data-v-7d33884c],
  .status_yellow_card[data-v-7d33884c] {
    width: 100%;
}
.status_yellow_card[data-v-7d33884c] {
    margin: 30px 0px;
}
.status_card[data-v-7d33884c] {
    box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
}
.card_description[data-v-7d33884c] {
    font-size: 17px;
    line-height: 30px;
}
.card_description[data-v-7d33884c] {
    margin-bottom: 20px;
}
.card_title_blue[data-v-7d33884c],
  .card_title_yellow[data-v-7d33884c],
  .card_title_green[data-v-7d33884c],
  .card_price_blue[data-v-7d33884c],
  .card_price_green[data-v-7d33884c],
  .card_price_yellow[data-v-7d33884c] {
    font-size: 24px;
}
.card_price_info > img[data-v-7d33884c] {
    height: 50px;
}
.items_one_row[data-v-7d33884c] {
    flex-wrap: wrap;
}
.start_content[data-v-7d33884c] {
    padding-left: 0px;
    padding-right: 0px;
}
.modal-content[data-v-7d33884c] {
    width: 100%;
}
.modal-dialog[data-v-7d33884c] {
    margin: 10px;
}
.modal-body[data-v-7d33884c] {
    padding: 20px;
}
.score_block img[data-v-7d33884c] {
    width: 1600px;
    height: auto;
}
.score_block[data-v-7d33884c] {
    margin-bottom: 0px !important;
}
.bottom_tired_title[data-v-7d33884c] {
    width: auto;
}
}
@media (max-width:700px){
.main_center[data-v-7d33884c]{
    max-width: 440px;
}
}
@media (max-width: 1260px) and (min-width: 992px) {
body[data-v-7d33884c] {
    min-width: 1260px;
}
}
@media (max-width: 800px) {
.table_img_close[data-v-7d33884c]{
    top: 34% !important;
    -webkit-transform: translate(943%, 4%);
            transform: translate(943%, 4%);
    right: 17% !important;
    left: 82% !important;
}
.table_img_block[data-v-7d33884c]{
    -webkit-transform: translate(-26%, 103%);
            transform: translate(-26%, 103%);
}
}
@media (max-width: 640px) {
.nav-link[data-v-7d33884c] {
    display: none;
}
.status_content[data-v-7d33884c] {
    padding: 100px 30px;
}
.start_two_item[data-v-7d33884c],
  .start_five_item[data-v-7d33884c] {
    margin: 0px;
}
.card_price_info[data-v-7d33884c] {
    top: 20px;
}
}
@media (max-width: 600px) {
.nav-item-two[data-v-7d33884c] {
    display: block;
}
.start_items_block[data-v-7d33884c]{
    margin-bottom: 0vh;
}
.start_block[data-v-7d33884c]{
    padding-bottom: 50px;
}
.start_content[data-v-7d33884c]{
    padding-top: 50px;
}
.table_bottom[data-v-7d33884c]{
    font-size: 16px;
    margin-top: 30px;
}
.nav-item-one[data-v-7d33884c] {
    display: none;
}
.top_title[data-v-7d33884c],
  .bottom_title[data-v-7d33884c] {
    font-size: 30px;
}
.top_description[data-v-7d33884c],
  .bottom_description[data-v-7d33884c] {
    font-size: 18px;
}
.work_content[data-v-7d33884c],.table_content[data-v-7d33884c] {
    padding: 35px 30px;
}
.status_content[data-v-7d33884c] {
    padding: 50px 30px;
}
.tired_content[data-v-7d33884c]{
    padding: 45px 0px;
}
.bottom_tired_title[data-v-7d33884c]{
    font-size: 16px;
    line-height: 30px;
    margin-top: 45px;
}
.values_content[data-v-7d33884c]{
    padding: 50px 0px;
}
.bottom_block_title[data-v-7d33884c]{
    font-size: 16px !important;
    line-height: 30px;
    margin-top: 45px;
}
.work_title_two[data-v-7d33884c]{
    font-size: 17px;
}
.value_item[data-v-7d33884c] {
    padding-top: 27px;
    width: 450px;
    height: 450px;
    background-size: 450px 450px;
    margin-bottom: 65px;
}
.three_row .value_item[data-v-7d33884c]{
    padding-top: 0;
}
.value_description[data-v-7d33884c]{
    font-size: 14px;
}
.status_title[data-v-7d33884c] {
    font-size: 30px;
}
.copyright_block[data-v-7d33884c],
  .social_media[data-v-7d33884c],
  .pdf_block[data-v-7d33884c] {
    display: block;
    text-align: center;
}
.pdf_block[data-v-7d33884c]{
    margin-left: 0;
    margin-top: 30px;
}
.telegram_block[data-v-7d33884c],
  .contract_block[data-v-7d33884c],
  .pdf_block[data-v-7d33884c] {
    justify-content: center;
}
.contract_block[data-v-7d33884c] {
    margin-left: 0px;
}
.telegram_block[data-v-7d33884c],
  .social_media[data-v-7d33884c],
  .pdf_block[data-v-7d33884c] {
    margin-bottom: 30px;
}
}
@media (max-width: 550px) {
.navbar-brand[data-v-7d33884c] {
    display: none;
}
.bottom_text .connection_is_good[data-v-7d33884c]{
    right: 25px;
}
.table_diagramma-img img[data-v-7d33884c]{
    width: 300px;
}
.navbar[data-v-7d33884c] {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: center;
}
header[data-v-7d33884c] {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
}
@media (max-width: 500px) {
.main_center[data-v-7d33884c] {
    margin-bottom: 35px;
}
.table_content[data-v-7d33884c]{
    overflow-x: scroll;
}
.three_row .value_item[data-v-7d33884c]{
    padding-top: 100px;
}
.main_center .main_logo_text[data-v-7d33884c] {
    width: 50%;
    height: auto;
}
.login_button[data-v-7d33884c] {
    width: 100px;
}
.main_logo_text[data-v-7d33884c] {
    width: 50px;
}
.register_button[data-v-7d33884c] {
    width: 140px;
}
.login_text[data-v-7d33884c],
  .register_text[data-v-7d33884c],
  .lang_text[data-v-7d33884c],
  .nav-link[data-v-7d33884c] {
    font-size: 14px;
}
.help-icon[data-v-7d33884c],
  .net-icon[data-v-7d33884c] {
    display: none;
}
.value_item[data-v-7d33884c] {
    padding-top: 50px;
    width: 400px;
    height: 400px;
    background-size: 400px 400px;
}
.score_block img[data-v-7d33884c] {
    width: 1300px;
    height: auto;
}
}
@media (max-width: 460px) {
.value_item[data-v-7d33884c] {
    width: 350px;
    height: 350px;
    background-size: 350px 350px;
}
.table_img_close[data-v-7d33884c]{
    top: 33% !important;
    left: 93% !important;
}
.value_description[data-v-7d33884c] {
    font-size: 13px;
}
.value_title[data-v-7d33884c] {
    font-size: 18px;
}
.card_description[data-v-7d33884c] {
    font-size: 14px;
    line-height: 26px;
}
.card_description[data-v-7d33884c] {
    margin-top: 20px;
}
}
@media (max-width: 430px) {
.start_two_item[data-v-7d33884c] {
    margin-top: 20px;
}
.main_center[data-v-7d33884c] {
    max-width: 300px;
}
.fade-item[data-v-7d33884c] {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    flex-direction: column;
}
.fade-item.reverse[data-v-7d33884c] {
    flex-direction: column;
}
.fade-item > img[data-v-7d33884c] {
    width: 100%;
}
.fade-item-text[data-v-7d33884c] {
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    margin-top: 20px;
    width: 80%;
}
.fade-item-title[data-v-7d33884c] {
    font-size: 18px;
}
.fade-item-desc[data-v-7d33884c] {
    font-size: 16px;
}
.top_title[data-v-7d33884c],
  .bottom_title[data-v-7d33884c],
  .status_title[data-v-7d33884c],
  .table_title[data-v-7d33884c],
  .start_title[data-v-7d33884c],
  .table_bottom[data-v-7d33884c] {
    font-size: 25px;
}
.tired_title[data-v-7d33884c] {
    font-size: 28px;
}
.bottom_tired_title[data-v-7d33884c] {
    font-size: 22px;
}
.card_price_info[data-v-7d33884c] {
    top: 10px;
}
.top_description[data-v-7d33884c],
  .main_banner_title[data-v-7d33884c],
  .bottom_description[data-v-7d33884c] {
    font-size: 18px;
}
.main_content[data-v-7d33884c],
  .work_content[data-v-7d33884c],
  .values_content[data-v-7d33884c],
  .table_content[data-v-7d33884c]{
    padding-left: 10px;
    padding-right: 10px;
}
.work_title[data-v-7d33884c],
  .bottom_block_title[data-v-7d33884c],
  .values_title[data-v-7d33884c] {
    font-size: 25px;
}
.work_text[data-v-7d33884c],.table_text[data-v-7d33884c] {
    font-size: 16px;
    line-height: 27px;
}
.work_text span[data-v-7d33884c] {
    font-size: 17px;
    line-height: 27px;
}
}
@media (max-width: 405px) {
.fade-item[data-v-7d33884c] {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.table_img_close[data-v-7d33884c]{
    top: 32% !important;
    left: 109% !important;
}
.table_img_block[data-v-7d33884c]{
    -webkit-transform: translate(-22%, 103%);
            transform: translate(-22%, 103%);
}
.fade-item > img[data-v-7d33884c] {
    width: 90%;
}
.fade-item-text[data-v-7d33884c] {
    text-align: center;
    margin-top: 20px;
    width: 80%;
}
.fade-item-title[data-v-7d33884c] {
    font-size: 16px;
    color: white;
    font-weight: 700;
}
.fade-item-desc[data-v-7d33884c] {
    font-size: 14px;
    color: white;
    font-weight: 400;
}
.value_item[data-v-7d33884c] {
    width: 255px;
    height: 255px;
    background-size: 255px 255px;
}
.value_title[data-v-7d33884c] {
    font-size: 14px;
    margin-top: 0;
}
.value_description[data-v-7d33884c] {
    font-size: 11px;
    line-height: 16px;
    width: 255px;
}
.three_row .value_item[data-v-7d33884c] {
    padding-top: 85px;
}
.tired_title[data-v-7d33884c] {
    font-size: 28px;
}
.bottom_tired_title[data-v-7d33884c] {
    font-size: 25px;
}
}
@media (max-width: 370px) {
.login_button[data-v-7d33884c] {
    margin-left: 10px;
}

  /* .lang_button img {
    display: none;
  } */
.btn-group[data-v-7d33884c] {
    margin-left: 0px;
}
.dropdown-item[data-v-7d33884c] {
    padding: 0.25rem 5px;
}
.main_banner_title[data-v-7d33884c],
  .top_description[data-v-7d33884c],
  .bottom_description[data-v-7d33884c],
  .work_text[data-v-7d33884c],.table_text[data-v-7d33884c] {
    font-size: 15px;
}
.work_text span[data-v-7d33884c] {
    font-size: 16px;
}
.top_title[data-v-7d33884c],
  .bottom_title[data-v-7d33884c],
  .main_banner_description[data-v-7d33884c],
  .work_title[data-v-7d33884c],
  .bottom_block_title[data-v-7d33884c],
  .values_title[data-v-7d33884c] {
    font-size: 17px;
    line-height: 30px;
}
.card_title_blue[data-v-7d33884c],
  .card_title_yellow[data-v-7d33884c],
  .card_title_green[data-v-7d33884c],
  .card_price_blue[data-v-7d33884c],
  .card_price_yellow[data-v-7d33884c],
  .card_price_green[data-v-7d33884c] {
    font-size: 20px;
}
.card_price_info[data-v-7d33884c] {
    top: 10px;
}
.card_price_info > img[data-v-7d33884c] {
    height: 40px;
}
.card_description[data-v-7d33884c],
  .start_item_description[data-v-7d33884c],
  .copyright_text[data-v-7d33884c],
  .copyright_link[data-v-7d33884c],
  .telegram_block span[data-v-7d33884c],
  .contract_block span[data-v-7d33884c],
  .pdf_block[data-v-7d33884c] {
    font-size: 13px;
    line-height: 22px;
}
.card_description[data-v-7d33884c] {
    margin-bottom: 20px;
}
.tired_title[data-v-7d33884c] {
    font-size: 28px;
}
.bottom_tired_title[data-v-7d33884c] {
    font-size: 25px;
}
.fade-item[data-v-7d33884c] {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fade-item > img[data-v-7d33884c] {
    width: 90%;
}
.fade-item-text[data-v-7d33884c] {
    text-align: center;
    margin-top: 20px;
    width: 80%;
}
.fade-item-title[data-v-7d33884c] {
    font-size: 16px;
    color: white;
    font-weight: 700;
}
.fade-item-desc[data-v-7d33884c] {
    font-size: 14px;
    color: white;
    font-weight: 400;
}
}
@media (max-width: 350px) {
.white_item_decor[data-v-7d33884c]{
    width: 145px;
}
.status_content img[data-v-7d33884c]{
    width: 140px;
}
}
@media (max-width: 320px) {
body[data-v-7d33884c] {
    min-width: 320px;
}
.register_button[data-v-7d33884c]{
    width: 120px;
}
.login_button[data-v-7d33884c]{
    width: 80px;
}
.work_content img[data-v-7d33884c]{
    width: 150px;
}
}
