.title[data-v-70d50a02] {
  margin-left: 20px;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}
.content[data-v-70d50a02] {
  padding-top: 180px;
  margin-left: 30px;
  margin-right: 30px;
}
.top_block[data-v-70d50a02] {
  display: flex;
  align-items: center;
}
.img_block[data-v-70d50a02]{
  position: relative;
  margin-left: 25px;
}
.block[data-v-70d50a02] {
  margin-top: 50px;
  width: 100%;
  height: 560px;
  background-image: url("../images/background-screen.png");
  background-size: 100% 100%;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  background-repeat: no-repeat;
  padding: 100px 50px;
  display: flex;
  align-items: flex-end;
}
.img[data-v-70d50a02] {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.username[data-v-70d50a02] {
  text-align: center;
  font-size: 38px;
  color: #fff;
  font-weight: 700;
}
.information[data-v-70d50a02] {
  padding-top: 60px;
  width: 100%;
  margin-left: 70px;
}
.contact_item_text[data-v-70d50a02] {
  margin-bottom: 20px;
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
  font-weight: 400;
  font-family: "Roboto",sans-serif;
}
.contact_input[data-v-70d50a02] {
  width: 400px;
  height: 56px;
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 3px 4px rgba(58, 127, 230, 0.36);
  border-radius: 15px;
  border: 0;
  -webkit-appearance: none;
  outline: 0;
  padding: 20px;
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
}
.contact_input_block[data-v-70d50a02] {
  display: flex;
  align-items: center;
}
.contacts[data-v-70d50a02] {
  margin-top: 70px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.contact_item[data-v-70d50a02] {
  margin-bottom: 50px;
}

/* media adaptive */
@media (max-width: 1605px) {
.username[data-v-70d50a02] {
    font-size: 30px;
}
}
@media (max-width: 1705px) {
.contact_input[data-v-70d50a02] {
    width: 300px;
}
}
@media (max-width: 1530px) {
.contact_input[data-v-70d50a02] {
    width: 230px;
}
.block[data-v-70d50a02] {
    padding: 100px 20px;
}
}
@media (max-width: 1300px) {
.contact_input[data-v-70d50a02] {
    width: 200px;
}
.information[data-v-70d50a02] {
    margin-left: 40px;
}
}
@media (max-width: 992px) {
.img[data-v-70d50a02] {
    margin-right: 25px;
}
.block[data-v-70d50a02] {
    height: auto;
    padding: 90px 20px;
    display: block;
}
.information[data-v-70d50a02] {
    margin-left: 0px;
}
.img_block[data-v-70d50a02] {
    text-align: center;
    position: relative;
}
.contact_input[data-v-70d50a02] {
    width: 100%;
}
.contacts[data-v-70d50a02] {
    margin-top: 50px;
    display: block;
}
.content[data-v-70d50a02] {
    margin-left: 10px;
    margin-right: 10px;
}
}
@media (max-width: 700px) {
.content[data-v-70d50a02] {
    padding-top: 150px;
}
.block[data-v-70d50a02] {
    padding-bottom: 50px;
}
}
@media (max-width: 600px) {
.username[data-v-70d50a02] {
    font-size: 25px;
}
}
@media (max-width: 500px) {
.contact_input[data-v-70d50a02],
  .contact_item_text[data-v-70d50a02] {
    margin-left: 0px;
}
.contact_input_block[data-v-70d50a02] {
    display: block;
}
.contact_input_block img[data-v-70d50a02] {
    display: none;
}
}
@media (max-width: 370px) {
.title[data-v-70d50a02],
  .username[data-v-70d50a02] {
    font-size: 20px;
}
.contact_item_text[data-v-70d50a02],
  .contact_input[data-v-70d50a02] {
    font-size: 14px;
}
}
