.general_screen[data-v-72a943ce] {
  padding-top: 180px;
  margin-left: 30px;
  margin-right: 30px;
}
.general_title_block[data-v-72a943ce] {
  display: flex;
  align-items: center;
}
.general_title[data-v-72a943ce] {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin-left: 20px;
}
.general_search_input[data-v-72a943ce] {
  outline: 0;
  padding: 0px 20px;
  width: 370px;
  height: 50px;
  border-radius: 5px;
  border: 0;
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}
.search_button[data-v-72a943ce] {
  margin-left: 20px;
  width: 150px;
  height: 50px;
  border-radius: 5px;
  background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.2) 0%, rgba(24, 41, 101, 0.2) 100%);
  -webkit-backdrop-filter: blur(25.5652px);
          backdrop-filter: blur(25.5652px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.1s;
  transition: 0.1s;
}
.search_button[data-v-72a943ce]:hover {
  opacity: 0.5;
}
.search_button p[data-v-72a943ce] {
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
}
.general_search_block[data-v-72a943ce] {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.general_top_block[data-v-72a943ce] {
  display: flex;
  align-items: center;
}
.general_levels_block[data-v-72a943ce] {
  margin-top: 50px;
  display: flex;
  align-items: center;
}
tbody[data-v-72a943ce]{
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}
.general_table[data-v-72a943ce] {
  overflow-y: hidden;
  width: 100%;
  margin-top: 50px;
}
.head_table[data-v-72a943ce]{
  background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.5) 0%, rgba(24, 41, 101, 0.5) 100%);
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  border-radius: 15px;
}
.copy_input[data-v-72a943ce] {
  outline: 0;
  color: #fff;
  background-color: transparent;
  border: 0;
  width: 100px;
}
.main_row[data-v-72a943ce] {
  text-align: center;
  height: 68px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  border-left: 1px #656c76 solid;
}
.child_row[data-v-72a943ce] {
  text-align: center;
  height: 64px;
  background-color: rgba(57, 64, 74, 0.5);
  font-size: 18px;
  color: #fff;
  border-left: 1px #656c76 solid;
  border-bottom: 1px #656c76 solid;
}
.child_row[data-v-72a943ce]:nth-child(1) {
  border-left: 0px;
}
.purple_text[data-v-72a943ce] {
  color: #b19cd9;
}
.green_text[data-v-72a943ce] {
  color: #5cd58e;
}
.blue_text[data-v-72a943ce] {
  color: #54cdef;
}
.yellow_text[data-v-72a943ce] {
  color: #f2ca6b;
}
.success_text[data-v-72a943ce] {
  color: #5cd58e;
}
.lost_text[data-v-72a943ce] {
  color: #ff4646;
}
tr[data-v-72a943ce]:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.general_table_block[data-v-72a943ce] {
  margin-top: 50px;
}
.popover_button[data-v-72a943ce] {
  background-color: transparent;
  border: 0;
}
.popover__wrapper[data-v-72a943ce] {
  position: relative;
  display: inline-block;
}
.popover__content[data-v-72a943ce] {
  opacity: 0;
  visibility: hidden;
  border: 1px solid #f2ca6b;
  position: absolute;
  left: -150px;
  -webkit-transform: translate(0, 10px);
          transform: translate(0, 10px);
  border-radius: 5px;
  background-color: #384152;
  padding: 10px 10px 10px 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: 240px;
  height: 130px;
  top: 50px;
  text-align: left !important;
  overflow: hidden;
}
.popover__content[data-v-72a943ce]:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(50% - 10px);
  top: -8px;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.popover__wrapper:hover .popover__content[data-v-72a943ce] {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(0, -20px);
          transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.child_content[data-v-72a943ce] {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.info_popover_icon[data-v-72a943ce] {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-top: -12px;
}
.user_id[data-v-72a943ce] {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
.status_item[data-v-72a943ce],
.sponsor_id[data-v-72a943ce],
.country_id[data-v-72a943ce] {
  font-size: 14px;
  color: #fff;
}
.status_text[data-v-72a943ce] {
  color: #f2ca6b;
  font-weight: 700;
  font-size: 14px;
}
.status_text_blue[data-v-72a943ce] {
  color: #54cdef;
  font-weight: 700;
  font-size: 14px;
}
.status_text_free[data-v-72a943ce] {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
.sponsor_text[data-v-72a943ce],
.country_text[data-v-72a943ce] {
  font-weight: 700 !important;
  color: #fff !important;
}
.social_text[data-v-72a943ce] {
  font-size: 14px;
  color: #fff;
  margin-left: 10px;
}
.social_item[data-v-72a943ce] {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.prev_button[data-v-72a943ce] {
  width: 250px;
  height: 50px;
  background-color: #728ab3;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  border: 0;
  outline: 0;
  transition-duration: 0.1s;
  transition: 0.1s;
}
.prev_button[data-v-72a943ce]:hover {
  opacity: 0.5;
}
.right_icon[data-v-72a943ce] {
  margin-right: 5px;
}
.left_icon[data-v-72a943ce] {
  margin-left: 5px;
}
.next_button[data-v-72a943ce] {
  float: right;
  width: 250px;
  height: 50px;
  background-color: #3b5b91;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  border: 0;
  outline: 0;
  transition-duration: 0.1s;
  transition: 0.1s;
}
.next_button[data-v-72a943ce]:hover {
  opacity: 0.5;
}
.buttons_block[data-v-72a943ce] {
  margin-top: 50px;
}
.copyInput[data-v-72a943ce] {
  outline: 0;
  color: #fff;
  background-color: transparent;
  border: 0;
}
.copy_button[data-v-72a943ce] {
  margin-left: 50px;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.tablinks[data-v-72a943ce] {
  width: 13.286%;
  background-color: transparent;
  border: 0;
}
#LevelTwo[data-v-72a943ce],
#LevelThree[data-v-72a943ce],
#LevelFour[data-v-72a943ce],
#LevelFive[data-v-72a943ce],
#LevelSix[data-v-72a943ce],
#LevelSeven[data-v-72a943ce] {
  display: none;
}
#LevelOne[data-v-72a943ce] {
  display: block;
}
.buttons_block[data-v-72a943ce] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table_page_indicator[data-v-72a943ce] {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}
.tab[data-v-72a943ce] {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
p[data-v-72a943ce] {
  margin: 0;
}

/* media screen */
@media (max-width: 1601px) {
.child_row p[data-v-72a943ce],
  .copyInput[data-v-72a943ce] {
    font-size: 15px;
}
.main_row p[data-v-72a943ce] {
    font-size: 18px;
}
}
@media (max-width: 1400px) {
.general_table[data-v-72a943ce] {
    margin-top: 30px;
}
}
@media (max-width: 992px) {
.general_screen[data-v-72a943ce] {
    margin-left: 10px;
    margin-right: 10px;
}
.general_search_input[data-v-72a943ce] {
    width: 100%;
}
.general_title[data-v-72a943ce] {
    font-size: 20px;
}
.search_button p[data-v-72a943ce] {
    font-size: 15px;
}
.tab[data-v-72a943ce]{
    display: block;
}
.tablinks[data-v-72a943ce] {
    width: 100%;
    margin-bottom: 20px;
}
}
@media (max-width: 800px) {
.general_table[data-v-72a943ce] {
    width: 1200px;
}
}
@media (max-width: 700px) {
.general_top_block[data-v-72a943ce] {
    display: block;
}
.general_title_block[data-v-72a943ce] {
    justify-content: center;
}
.general_search_block[data-v-72a943ce] {
    margin-top: 25px;
}
.general_screen[data-v-72a943ce] {
    padding-top: 150px;
}
}
@media (max-width: 630px) {
.next_button[data-v-72a943ce],
  .prev_button[data-v-72a943ce] {
    font-size: 14px;
    width: 200px;
}
.table_page_indicator[data-v-72a943ce] {
    font-size: 15px;
}
}
@media (max-width: 480px) {
.next_button[data-v-72a943ce],
  .prev_button[data-v-72a943ce] {
    width: 160px;
}
}
@media (max-width: 400px) {
.prev_button span[data-v-72a943ce],
  .next_button span[data-v-72a943ce] {
    display: none;
}
.next_button[data-v-72a943ce],
  .prev_button[data-v-72a943ce] {
    width: 100px;
}
.general_search_block[data-v-72a943ce] {
    display: block;
}
.search_button[data-v-72a943ce] {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
}
}
