*[data-v-b432b392],
p[data-v-b432b392],
a[data-v-b432b392],
li[data-v-b432b392],
ul[data-v-b432b392] {
  margin: 0;
  padding: 0;
}
div > .background[data-v-b432b392] {
  font-size: 17px;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  background-image: url("../../assets/images/login-back-new.jpg");
  background-repeat: top left no-repeat;
  background-attachment: fixed;
}
[data-v-b432b392]::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
[data-v-b432b392]::-webkit-scrollbar-button {
  background-color: #666;
}
[data-v-b432b392]::-webkit-scrollbar-track {
  background-color: #999;
}
[data-v-b432b392]::-webkit-scrollbar-track-piece {
  width: 50px;
  background-color: #ffffff;
}
[data-v-b432b392]::-webkit-scrollbar-thumb {
  width: 50px;
  height: 50px;
  background-color: #3b5b91;
  border-radius: 3px;
}
[data-v-b432b392]::-webkit-scrollbar-corner {
  width: 50px;
  background-color: #999;
}
[data-v-b432b392]::-webkit-resizer {
  width: 50px;
  background-color: #666;
}
a[data-v-b432b392],
a[data-v-b432b392]:hover {
  text-decoration: none;
}
.navbar-light .navbar-toggler-icon[data-v-b432b392] {
  background-image: url(../images/toggle-white.svg);
}
.navbar-light .navbar-toggler[data-v-b432b392] {
  color: rgba(242, 202, 107, 0.1);
}
.btn-group[data-v-b432b392],
.btn-group-vertical[data-v-b432b392] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
header[data-v-b432b392] {
  width: 100%;
  position: fixed;
  background: linear-gradient(
152.97deg
, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  height: 108px;
  z-index: 2050;
}
.navbar-expand-lg[data-v-b432b392] {
  padding: 0px 30px;
}
.container[data-v-b432b392],
.container-fluid[data-v-b432b392],
.container-lg[data-v-b432b392],
.container-md[data-v-b432b392],
.container-sm[data-v-b432b392],
.container-xl[data-v-b432b392],
.container-xxl[data-v-b432b392] {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: auto;
  margin-left: auto;
}
.navbar-collapse[data-v-b432b392] {
  justify-content: flex-end;
  background-image: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.navbar-brand[data-v-b432b392] {
  width: 100%;
  padding: 0px;
}
@media (min-width: 992px) {
.navbar-expand-lg .navbar-nav .dropdown-menu[data-v-b432b392] {
    position: absolute;
    background-color: transparent;
    border: none !important;
    min-width: 0;
    padding-left: 20px;
    padding-top: 10px;
}
}
.dropdown-item[data-v-b432b392]:focus,
.dropdown-item[data-v-b432b392]:hover {
  color: #fff;
  background-color: transparent;
}
.dropdown-item[data-v-b432b392] {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.navbar-light .navbar-nav .nav-link[data-v-b432b392] {
  color: #fff;
  font-size: 18px;
}
.container[data-v-b432b392],
.container-fluid[data-v-b432b392],
.container-lg[data-v-b432b392],
.container-md[data-v-b432b392],
.container-sm[data-v-b432b392],
.container-xl[data-v-b432b392],
.container-xxl[data-v-b432b392] {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
.command-dropdown[data-v-b432b392] {
  margin: 0px 15px;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: 0;
}
.navbar-nav[data-v-b432b392] {
  width: auto;
  display: flex;
  align-items: center;
}
.navbar[data-v-b432b392] {
  padding-top: 18px;
}
.lang_button[data-v-b432b392] {
  margin: 0px 15px;
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  color: #fff;
  transition-duration: 0.2s;
}
.lang_text[data-v-b432b392] {
  margin-left: 5px;
}
.navbar-expand-lg .navbar-nav .nav-link[data-v-b432b392] {
  padding: 0px;
  margin: 0px 15px;
}
.nav-item[data-v-b432b392] {
  position: relative;
}
.rect_border[data-v-b432b392]:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 4px;
  opacity: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.01193981010372902) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(0, 212, 255, 0.01193981010372902) 100%
  );
}
.nav-item:hover .rect_border[data-v-b432b392] {
  position: absolute;
  top: 35px;
  width: 100%;
  height: 4px;
  opacity: 100;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.01193981010372902) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(0, 212, 255, 0.01193981010372902) 100%
  );
}
.navbar-light .navbar-nav .nav-link[data-v-b432b392]:hover {
  color: #fff;
}

/* login block */
.login_block[data-v-b432b392] {
  height: auto;
  padding-top: 200px;
  padding-bottom: 100px;
}
.login_form_block[data-v-b432b392] {
  margin: 0 auto;
  width: 600px;
  height: auto;
  border-radius: 10px;
  border: .5px solid #F2CA6B;
  background-color: rgba(57, 64, 74, 0.8);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.top_block[data-v-b432b392] {
  margin: 0 auto;
  text-align: center;
}
.logo_form[data-v-b432b392] {
  width: 100px;
  height: 100px;
}
.login_content[data-v-b432b392] {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.form_header[data-v-b432b392] {
  width: 600px;
  height: 100px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  text-align: center;
}
.form_header_title[data-v-b432b392] {
  text-transform: uppercase;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin: 0 auto;
}
.wallet_input_block[data-v-b432b392],
.password_input_block[data-v-b432b392] {
  width: 500px;
  margin: 0 auto;
  margin-top: 50px;
}
.password_input_block[data-v-b432b392] {
  margin-top: 30px;
}
.wallet_input_block p[data-v-b432b392],
.password_input_block p[data-v-b432b392] {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}
.password_input_block p span[data-v-b432b392],
.wallet_input_block p span[data-v-b432b392] {
  color: #ff4646;
  margin-left: 4px;
}
.wallet_input[data-v-b432b392],
.password_input[data-v-b432b392] {
  font-size: 16px;
  width: 500px;
  height: 56px;
  border: 0px #92969b solid;
  border-radius: 15px;
  -webkit-appearance: none;
  box-shadow: 0px 3px 4px rgba(58, 127, 230, 0.36);
  background: rgba(255, 255, 255, 0.2);
  padding: 0px 20px;
  outline: 0;
  color: #fff;
}
[data-v-b432b392]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
[data-v-b432b392]:-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
[data-v-b432b392]::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
[data-v-b432b392]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.form_footer[data-v-b432b392] {
  text-align: center;
}
.forgot_link[data-v-b432b392],
.create_link[data-v-b432b392] {
  font-size: 16px;
  color: #fff;
  text-decoration: underline;
  transition-duration: 0.1s;
}
.forgot_link[data-v-b432b392]:hover,
.create_link[data-v-b432b392]:hover {
  opacity: 0.5;
  color: rgb(228, 228, 228);
  text-decoration: underline;
}
.login_button[data-v-b432b392] {
  padding: 16px 40px !important;
  background: #1E519E !important;
  border-radius: 6px;
  width: 100%;
  border: 0;
  font-size: 18px !important;
  font-weight: bold;
  color: #fff;
  margin-top: 30px;
  transition-duration: 0.1s;
  margin: 0 auto;
}
.login_button[data-v-b432b392]:hover {
  opacity: 0.5 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: bold;
}
.account_prompt[data-v-b432b392] {
  font-size: 16px;
  color: #fff;
  text-decoration: underline;
  transition-duration: 0.1s;
  margin-top: 20px;
}
.form_footer[data-v-b432b392] {
  margin-top: 30px;
}
.data_block[data-v-b432b392] {
  margin-top: 66px;
  margin-bottom: 43px;
  padding: 0px 50px;
}

/* footer */
footer[data-v-b432b392] {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: rgba(57, 64, 74, 0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  height: 130px;
  z-index: 2050;
}
.footer_content[data-v-b432b392] {
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.copyright_text[data-v-b432b392] {
  color: #fff;
  font-weight: 300;
  font-size: 16px;
}
.copyright_link[data-v-b432b392] {
  text-decoration: underline;
  color: #54cdef;
  font-size: 16px;
  transition-duration: 0.1s;
}
.copyright_link[data-v-b432b392]:hover {
  text-decoration: underline;
  color: #54cdef;
  opacity: 0.5;
}
.lang-group-btn[data-v-b432b392]{
  display: none;
}

/* media adaptive */
@media (max-width: 1920px) {
body[data-v-b432b392],
  html[data-v-b432b392] {
    overflow-x: hidden;
    width: 100%;
    height: auto;
}
}
@media (max-width: 992px) {
header[data-v-b432b392] {
    display: inherit;
    height: 92px;
}
.login_block[data-v-b432b392] {
    padding-top: 140px;
}
.navbar-collapse[data-v-b432b392]{
    padding-top: 20px !important;
    background-image: url("../images/mobile-background-top.png") !important;
    margin-top: 8px;
}
.nav-link[data-v-b432b392]{
    font-weight: 400;
}
.nav-item .btn-group[data-v-b432b392]{
    display: none !important;
}
.lang-group-btn[data-v-b432b392]{
    display: block !important;
    position: absolute;
    right: 50px;
    top: 32px;
}
.lang-group-btn .lang_button img[data-v-b432b392]{
    width: 28px;
    height: 28px;
}
.lang-group-btn .dropdown-toggle[data-v-b432b392]::after{
    content: none;
}
.lang-group-btn .dropdown-menu[data-v-b432b392]{
    min-width: 0;
    padding-left: 20px;
    border: 0 !important;
}
.lang-group-btn .dropdown-menu img[data-v-b432b392]{
    width: 28px;
    height: 28px;
}
.navbar-expand-lg .navbar-nav .nav-link[data-v-b432b392],
  .command-dropdown[data-v-b432b392],
  .lang_button[data-v-b432b392] {
    margin-bottom: 15px;
}
.navbar-expand-lg[data-v-b432b392] {
    padding: 10px 0px 0px;
}
.navbar-brand[data-v-b432b392] {
    width: 5%;
    padding-left: 10px;
}
.navbar-light .navbar-toggler[data-v-b432b392]{
    padding-right: 10px;
    border-color: none;
    color: transparent;
}
.login_form_block[data-v-b432b392] {
    margin-left: 20px;
    margin-right: 20px;
}
}
@media (max-width: 800px) {
.form_header[data-v-b432b392],
  .login_form_block[data-v-b432b392] {
    width: 100%;
}
.wallet_input[data-v-b432b392],
  .password_input[data-v-b432b392],
  .wallet_input_block[data-v-b432b392],
  .password_input_block[data-v-b432b392] {
    width: 350px;
}
}
@media (max-width: 500px){
header[data-v-b432b392] {
    height: 74px;
}
header .navbar-brand img[data-v-b432b392]{
    width: 55px !important;
}
.lang-group-btn[data-v-b432b392]{
    top: 24px;
}
}
@media (max-width: 590px) {
.footer_content[data-v-b432b392] {
    display: block;
}
footer[data-v-b432b392] {
    height: 90px;
}
.copyright_text[data-v-b432b392],
  .copyright_link[data-v-b432b392] {
    font-size: 13px;
}
}
@media (max-width: 415px) {
.form_header_title[data-v-b432b392] {
    font-size: 18px;
}
}
@media (max-width: 375px) {
.wallet_input[data-v-b432b392],
  .password_input[data-v-b432b392],
  .wallet_input_block[data-v-b432b392],
  .password_input_block[data-v-b432b392] {
    width: 230px;
}
}
@media (max-width: 350px) {
.form_header_title[data-v-b432b392] {
    font-size: 17px;
}
.copyright_text[data-v-b432b392],
  .copyright_link[data-v-b432b392] {
    font-size: 12px;
}
}
@media (max-width: 320px) {
body[data-v-b432b392] {
    min-width: 320px;
}
}

/* join screen */
.top_text[data-v-b432b392] {
  margin-top: 50px;
  font-size: 15px;
  color: #fff;
}
