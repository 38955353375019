.team_screen[data-v-5dd06fea] {
  padding-top: 180px;
  margin-left: 30px;
  margin-right: 30px;
}
.general_top_block[data-v-5dd06fea] {
  display: flex;
  align-items: center;
}
.general_title_block[data-v-5dd06fea] {
  display: flex;
  align-items: center;
}
.general_title[data-v-5dd06fea] {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin-left: 20px;
}
.general_search_block[data-v-5dd06fea] {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.general_search_input[data-v-5dd06fea] {
  outline: 0;
  padding: 0px 20px;
  width: 370px;
  height: 50px;
  border-radius: 5px;
  border: 1px #606065 solid;
  background-color: rgba(57, 64, 74, 0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}
.search_button[data-v-5dd06fea] {
  margin-left: 20px;
  width: 150px;
  height: 50px;
  border-radius: 5px;
  background: #728ab3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.1s;
  transition: 0.1s;
}
.search_button[data-v-5dd06fea]:hover {
  opacity: 0.5;
}
.search_button p[data-v-5dd06fea] {
  font-size: 16px;
  color: #fff;
  margin-left: 10px;
}
.tab[data-v-5dd06fea] {
  margin-top: 50px;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

/* Style the buttons inside the tab */
.tab button[data-v-5dd06fea] {
  width: 400px;
  height: 60px;
  background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.2) 0%, rgba(24, 41, 101, 0.2) 100%);
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 21px;
  color: #fff;
  font-weight: 700;
  border-radius: 17px;
  transition-duration: 0.1s;
}

/* Change background color of buttons on hover */
.tab button[data-v-5dd06fea]:hover {
  background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.9) 0%, rgba(24, 41, 101, 0.9) 100%);
}

/* Create an active/current tablink class */
.tab button.active[data-v-5dd06fea] {
  background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.9) 0%, rgba(24, 41, 101, 0.9) 100%);
}
.tab button[data-v-5dd06fea]:nth-child(2) {
  margin-left: 30px;
  margin-right: 30px;
}
.copy_green[data-v-5dd06fea] {
  background-color: transparent;
  border: 0;
  outline: 0;
}
.instructions[data-v-5dd06fea]{
  height: 26px;
  margin-left: 20px;
  cursor: pointer;
}
.copy_input[data-v-5dd06fea] {
  outline: 0;
  color: #fff;
  background-color: transparent;
  border: 0;
  width: 100px;
}
#carouselExampleControls[data-v-5dd06fea] {
  margin-top: 50px;
}
.register_text[data-v-5dd06fea] {
  color: #fff !important;
}
.free_status[data-v-5dd06fea] {
  color: #728ab3 !important;
}
tbody[data-v-5dd06fea]{
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}
.general_table[data-v-5dd06fea] {
  margin-top: 50px;
}
.head_table[data-v-5dd06fea]{
  background: linear-gradient(152.97deg, rgba(68, 133, 230, 0.5) 0%, rgba(24, 41, 101, 0.5) 100%);
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  border-radius: 15px;
}
.table_page_indicator[data-v-5dd06fea] {
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}
.buttons_block[data-v-5dd06fea] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* media adaptive */
@media (max-width: 1601px) {
.child_content p[data-v-5dd06fea],
  .child_row p[data-v-5dd06fea] {
    font-size: 15px;
}
.main_row p[data-v-5dd06fea] {
    font-size: 18px;
}
.tab button[data-v-5dd06fea] {
    font-size: 18px;
}
}
@media (max-width: 992px) {
.tab button[data-v-5dd06fea] {
    font-size: 14px;
}
.team_screen[data-v-5dd06fea] {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 130px;
}
}
@media (max-width: 800px) {
.tab[data-v-5dd06fea] {
    flex-direction: column;
    align-items: center;
}
.tab button[data-v-5dd06fea] {
}
.tab button[data-v-5dd06fea]:nth-child(2) {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.general_table[data-v-5dd06fea] {
    width: 1200px;
}
}
