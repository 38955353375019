*[data-v-966d8bb2],p[data-v-966d8bb2],a[data-v-966d8bb2],li[data-v-966d8bb2],ul[data-v-966d8bb2] {
	margin: 0;
	padding: 0;
}
body[data-v-966d8bb2] {
	font-size: 17px;
	font-family: 'Open Sans', sans-serif;
	overflow-x: hidden;
	background-image: url("../../assets/images/login-back-new.jpg");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
}
a[data-v-966d8bb2], a[data-v-966d8bb2]:hover {
	text-decoration: none;
}


/* forgot block */
.forgot_block[data-v-966d8bb2] {
  padding-top: 350px;
  padding-bottom: 300px;
}
.forgot_content[data-v-966d8bb2] {
	display: flex;
	align-items: center;
	height: 100%;
}
.forgot_form_block[data-v-966d8bb2] {
	margin: 0 auto;
	width: 600px;
	height: auto;
	border-radius: 10px;
	background-color: rgba(57, 64, 74,0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding-bottom: 66px;
}
.top_block[data-v-966d8bb2] {
	  padding-bottom: 50px;
    margin: 0 auto;
    text-align: center;
}
.logo_form[data-v-966d8bb2] {
    width: 100px;
    height: 100px;
}
.form_header[data-v-966d8bb2] {
	width: 600px;
	height: 100px;
	border-radius: 10px 10px 0px 0px;
	display: flex;
	align-items: center;
	text-align: center;
	margin-bottom: 50px;
}
.form_header_title[data-v-966d8bb2] {
	font-size: 20px;
	color: #fff;
	font-weight: 700;
	margin: 0 auto;
	text-transform: uppercase;
}
.email_input_block[data-v-966d8bb2] {
	width: 500px;
	margin: 0 auto;
}
.email_input_block p[data-v-966d8bb2] {
	color: #fff;
	margin-bottom: 10px;
	font-size: 18px;
    font-weight: 600;
}
.email_input_block p span[data-v-966d8bb2] {
	color: #FF4646;
  margin-left: 4px;
}
.email_input[data-v-966d8bb2] {
	font-size: 16px;
	width: 500px;
	height: 56px;
	border: 0px #92969b solid;
	border-radius: 15px;
	box-shadow: 0px 3px 4px rgba(58, 127, 230, 0.36);
	background: rgba(255, 255, 255, 0.2);
	padding: 0px 20px;
	outline: 0;
	color: #fff;
}
.form_footer[data-v-966d8bb2] {
	padding: 0px 50px;
	margin-top: 76px;
	text-align: center;
}
.forgot_button[data-v-966d8bb2] {
	padding: 16px 40px;
	background: #1E519E;
	border-radius: 6px;
	border: 0px;
	font-size: 18px;
	width: 100%;
	font-weight: bold;
	color: #fff;
	transition-duration: .1s;
}
.forgot_button[data-v-966d8bb2]:hover {
	opacity: .5;
	color: #fff;
}
.forgot_footer_title[data-v-966d8bb2] {
    font-size: 16px;
    color: #fff;
	text-decoration: underline;
}
.forgot_footer_link[data-v-966d8bb2] {
	font-size: 16px;
    color: #fff;
    text-decoration: underline;
    transition-duration: .1s;
}
.forgot_footer_link[data-v-966d8bb2]:hover {
	  color: #fff;
	  opacity: .5;
	  text-decoration: underline;
}
.forgot_footer[data-v-966d8bb2] {
	margin-top: 46px;
	text-align: center;
}
@media (max-width: 1920px) {
body[data-v-966d8bb2], html[data-v-966d8bb2] {
		width: 100%;
		height: auto;
		overflow-x: hidden;
}
}
@media (max-width: 992px) {
.forgot_form_block[data-v-966d8bb2] {
		margin-left: 20px;
		margin-right: 20px;
}
}
@media (max-width: 800px) {
.form_header[data-v-966d8bb2],.forgot_form_block[data-v-966d8bb2] {
		width: 100%;
}
.email_input[data-v-966d8bb2], .email_input_block[data-v-966d8bb2] {
		width: 350px;
}
}
@media (max-width: 415px) {
.form_header_title[data-v-966d8bb2] {
		font-size: 18px;
}
}
@media (max-width: 375px) {
.email_input[data-v-966d8bb2], .email_input_block[data-v-966d8bb2] {
		width: 230px;
}
}
@media (max-width: 350px) {
.form_header_title[data-v-966d8bb2] {
		font-size: 17px;
}
}
@media (max-width: 320px) {
body[data-v-966d8bb2] {
		min-width: 320px;
}
}